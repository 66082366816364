import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyCzOhkFGfzZs1RNiQtCDxySQBe1mEPMRww",
    authDomain: "gofood-il.firebaseapp.com",
    projectId: "gofood-il",
    storageBucket: "gofood-il.appspot.com",  
    messagingSenderId: "426344591666",
    appId: "1:426344591666:web:8edffab5be6e768fe2272b",
    measurementId: "G-LKVX6RB6JQ"
};

// init firebase
var myApp = firebase.initializeApp(firebaseConfig);

// init services 

const projectFirestore = myApp.firestore();
const projectAuth = myApp.auth();
const projectAuthVer = firebase.auth;
const projectStorage = myApp.storage();
 
const projectFunctions= myApp.functions('europe-west2');

//oren
// projectFunctions.useEmulator("localhost", 5001);
 
const FireStore = firebase.firestore;

myApp.auth().useDeviceLanguage();


export { projectFirestore,projectStorage, projectAuth, FireStore, projectFunctions, projectAuthVer};